import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";
import imgAuthor from "../../assets/image/jpeg/author.jpg";

const SectionStyled = styled(Section)``;

const Author = styled(Box)`
  min-width: 271px;
  max-width: 271px;
  min-height: 271px;
  max-height: 271px;
  border-radius: 500px;
  overflow: hidden;
  margin-bottom: 30px;
  @media ${device.lg} {
    margin-bottom: 0;
  }
`;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled bg="#f6f6f8">
      <Container>
        <Row>
          <Col xs="12" className="mb-5">
            <div className="text-center">
              <Title>Why Napa Vertical Solves the Problem</Title>
            </div>
          </Col>
          <Col lg="4" className="offset-lg-1 mb-4 mb-lg-0">
            <Author>
              <img src={imgAuthor} alt="Author" />
            </Author>
          </Col>
          <Col lg="7" className="pr-lg-5">
            <div className="author-text">
              <Text variant="small" my={4}>
                Though we can’t promise to calm the kids down, we can guarantee
                a more productive and organized working environment for your
                staff. Our system is specially formulated to integrate
                seamlessly with your existing operations, while our simple
                interface gives everyone the ease of use they’ve been looking
                for.
              </Text>
              <Text variant="small">
                So, next time you have a queue of requests outside your office,
                simply use your sticky notes to display the details of your
                newly integrated facility management software solution. From
                there, discard the sticky notes and enjoy a holistic,
                cloud-based management software tool.
              </Text>
              <Text variant="small" mt={4}>
                {" "}
                Breathe a sigh of relief knowing the chaos will soon turn into a
                well-run system where Napa Vertical is always there to provide
                excellent support.{" "}
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
