import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/schools/Content1";
import Hero from "../sections/schools/Hero";
import Wnapa from "../sections/schools/Wnapa";
import Call from "../sections/schools/Call";
import Testimonial from "../sections/schools/Testimonial";
const school = () => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Hero />
        <Content1 />
        <Testimonial />
        <Wnapa />
        <Call />
      </PageWrapper>
    </>
  );
};
export default school;
